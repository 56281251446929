
import { authModule } from '@t/session';
import { vxm } from '@/store';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IProject } from '@/entity/project/project';
import { NU } from '@t/type';
import { ProviderState, Providers } from '@microsoft/mgt';
import { projectApi } from '@api/project-api';

@Component({})
export default class BaseHeader extends Vue {
    @Prop({ required: false, default: 'ED4305' }) protected borderColor!: string;

    public selectedLanguage: string = '';
    private filterData: string = '';
    private allProjects: IProject[] = [];

    get projects(): IProject[] {
        let list: IProject[] = [];
        if (this.allProjects.length > 0) {
            list = this.allProjects;
        }
        else list = vxm.project.dropdownProjectListOptions;
        if (this.filterData && this.filterData !== '') {
            return list.filter((item) => item.designation.toUpperCase().startsWith(this.filterData.toUpperCase()) || 
                item.trigram.toUpperCase().startsWith(this.filterData.toUpperCase()))
                .sort((a, b) => {
                    const aIndex = a.designation.toUpperCase().indexOf(this.filterData.toUpperCase()) + a.trigram.toUpperCase().indexOf(this.filterData.toUpperCase());
                    const bIndex = b.designation.toUpperCase().indexOf(this.filterData.toUpperCase()) + b.trigram.toUpperCase().indexOf(this.filterData.toUpperCase());
                    if (aIndex === bIndex) {
                        return a.designation.localeCompare(b.designation) + a.trigram.localeCompare(b.trigram);
                    }
                    return aIndex - bIndex;
                });
        }
        return list;
    }

    get anyProjects() {
        return this.projects != null && this.projects.length > 0;
    }

    private currentRoute = this.$router.currentRoute.name;

    private loading: boolean = false;

    private lastLangStorageName: string = 'last-language';

    async mounted(): Promise<void> {
        this.loading = true;
        const lastLang = localStorage.getItem(this.lastLangStorageName);
        if (lastLang && lastLang.length > 1) {
            this.selectLanguage(lastLang);
        } else {
            this.selectLanguage('en');
        }

        vxm.user.checkIsAssociate();
        vxm.user.checkIsStudioManager();
        vxm.user.checkIsDaf();
        vxm.user.checkIsCP();

        if (vxm.user.isAssociate || vxm.user.isStudioManager || vxm.user.isDaf) {
            const call = await projectApi.getAllBase();
            if (call != null && call.cancelled === false && !call.error && call.datas != null) {
                this.allProjects = call.datas;
            }
        }
         else await vxm.project.fetchDropDownProjectOptions();


        /* .then((x) => {
            if (this.projects.length > 0) {
                const lsVar = localStorage.getItem('selected-project');
                if (lsVar) {
                    this.updateProject(this.projects.find(x => x.id === Number(lsVar)) as IProject);
                } else {
                    this.updateProject(this.projects[0]);
                }
            }
        }); */

        this.loading = false;
        this.routeIsValidForProjectSelector;
    }

    @Watch('projects')
    private firstLoadSelect(newVal: IProject[]) {
        if (newVal.length > 0) {
            const lsVar = localStorage.getItem('selected-project');
            if (lsVar) {
                this.updateProject(newVal.find((x) => x.id === Number(lsVar)) as IProject);
            } else {
                this.updateProject(newVal[0]);
            }
        }
    }

    updateProject(project: IProject): void {
        if (project) {
            vxm.project.updateSelectedProject(project.id + '');
            if (this.currentRoute == 'project-information' && this.$route.params.id != String(project.id)) {
                this.$router.replace({ name: 'project-information', params: { id: project.id + '' } });
            }
            localStorage.setItem('selected-project', String(project.id));
        }
    }

    onLoginCompleted(): void {
        const DBOpenRequest = window.indexedDB.open('mgt-users');
        DBOpenRequest.onsuccess = (e) => {
            DBOpenRequest.result.transaction('users', 'readwrite').objectStore('users').clear();
        };
    }

    onLogoutCompleted(): void {
        Providers.globalProvider.setState(ProviderState.SignedOut);
    }

    get isSidebarCollapsed(): boolean {
        return vxm.app.sidebarCollapsed;
    }

    get currentUserId(): string | undefined {
        return authModule.getAccount()?.localAccountId;
    }

    get routeIsValidForProjectSelector(): boolean {
        return (
            ['home', 'projectPlanification', 'projectManagements', 'subcontracting', 'provisionalfee','project-information'].findIndex(
                (x) => x === this.currentRoute
            ) !== -1
        );
    }

    get currentProject(): NU<IProject> {
        return vxm.project.dropdownProject;
    }

    get titleMain(): string {
        return vxm.app.titleValueMain;
    }

    get titleExt(): string {
        return vxm.app.titleValueExt;
    }

    public selectLanguage(newLanguage: string): void {
        this.selectedLanguage = newLanguage;
        localStorage.setItem(this.lastLangStorageName, this.selectedLanguage);
    }

    public setBackground(): boolean {
        return this.$route.name !== 'login' && this.$route.name !== 'externalValidation';
    }

    @Watch('$route')
    private updateRoute(newVal: any) {
        this.currentRoute = newVal.name;
    }
}
